import { PageProps } from 'gatsby';

import { PersonalDetailsPage } from '@src/modules/PersonalDetails/PersonalDetailsPage';
import { useCustomMixpanel } from '@services/Mixpanel';

const Details = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  return <PersonalDetailsPage mixpanel={mixpanel} {...props} />;
};

export default Details;
